.exploreBtn {
  background-color: #86bc42 !important;
  border-radius: 8px !important;
  color: #fff !important;
  font-weight: 700 !important;
  padding: 0.6rem 1.5rem !important;
}
.exploreBtn:hover {
  background-color: #86bc42 !important;
}
.product-choose-item {
  width: 100%;
  border-radius: 15px;
  max-width: calc(100% - 5px);
}
.product-choose-item > img {
  width: 66%;
  cursor: pointer;
  border-radius: 15px;
}
@media (max-width: 768px) {
  .small-img {
    display: inline-flex;
    justify-content: space-around;
    align-items: center;
    order: 2;
  }
  .larger-img {
    order: 1;
  }
  .product-choose-item > img {
    width: 100%;
    cursor: pointer;
    border-radius: 15px;
  }
  .item-avatar {
    display: grid !important;
    justify-content: start;
    align-items: center;
  }
  .avatar {
    margin-bottom: 0.5rem !important;
    width: 60px !important;
    height: 60px !important;
  }
  .avatarOrder {
    width: 60px !important;
    height: 60px !important;
  }
  .item-name {
    font-weight: 600 !important;
    font-size: 14px !important;
  }
  .item-avatar span {
    max-width: 100px !important; /* Adjust max-width as needed */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .price-bar {
    display: none !important;
  }
  .tax-box {
    width: 100% !important;
    text-align: end !important;
  }
  /* .viewimg{
        display: block !important;
    }
    .sm-img{
        display: flex !important;
        flex-direction: row !important;
        order: 2 !important;
    }*/
  .img-lg-item {
    width: 100% !important;
    height: auto !important;
  }
  .view-lg-img {
    width: 100% !important;
    height: auto !important;
  }
  .view-lg-img img {
    width: 100% !important;
    height: auto !important;
  }
  .sm-img {
    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: space-between !important;
    flex-direction: row !important;
    height: auto !important;
    width: 100% !important;
    padding: 10px 0px !important;
  }
  .view-sm-img img {
    height: 100% !important;
  }
  .viewimg {
    flex-direction: column !important;
  }

  .img-lg-item {
    order: -1 !important;
  }
  .milletHead {
    font-size: 20px !important;
  }
  .comment-rating {
    font-size: 14px !important;
    margin-top: 2px !important;
  }
  .mobile-quantityhead {
    display: none !important;
  }
  .mobile-quantityhead2 {
    display: block !important;
  }
  .increDecQ {
    padding: 13px 1px !important;
  }
  .viewBuy-now {
    background-color: #86bc42 !important;
    padding: 5px 18px !important;
    border-radius: 20px !important;
    font-weight: 600 !important;
    font-size: 12px !important;
  }
  .viewadd-cart {
    background-color: transparent !important;
    color: #000 !important;
    border: 1px solid #000 !important;
    padding: 5px 15px !important;
    border-radius: 20px !important;
    font-weight: 600 !important;
    font-size: 12px !important;
  }
  .filter {
    display: none !important;
  }
  .sticky {
    position: static !important;
  }
  .flitericon {
    display: contents !important;
  }
  .termsandcondition-Head::after {
    width: 200px !important;
  }
  .sm-hide {
    display: none !important;
  }
  .sm-block {
    display: contents !important;
  }
  .sm-myorder {
    display: none !important;
  }
  .sm-myorderView {
    display: contents !important;
  }
  .sm-delivered {
    display: none !important;
  }
  .sm-hide-Odetails {
    display: none !important;
  }
  .sm-deliveredDetailsView {
    display: contents !important;
  }
  .hamburger-btn {
    display: contents !important;
  }
  .lg-Search {
    margin-left: 1rem !important;
  }
  .locationBtn {
    display: none !important;
  }
}
.lg-Search {
  margin-left: 2rem;
}
.hamburger-btn {
  display: none !important;
}
.css-96uuyl {
  border-radius: 50px !important;
  box-shadow: 0px 3px 2px 0px #86bc42 !important;
}
.css-13hrbq3 {
  border-radius: 50px !important;
  /* box-shadow: 0px 3px 2px 0px #86BC42 !important; */
  height: 35px;
}
.css-104zre5-MuiInputBase-root .MuiInputBase-input::placeholder {
  color: #858585 !important;
}
.locationBtn {
  box-shadow: 0px 3px 2px 0px #86bc42 !important;
  border-radius: 50px !important;
  width: 143px !important;
  height: 35px;
}
.locationBtn span {
  font-size: 10px !important;
  font-weight: 600 !important;
  color: #858585 !important;
}
.css-hip9hq-MuiPaper-root-MuiAppBar-root {
  background-color: #fff !important;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: #0d354f !important;
}
.sm-deliveredDetailsView {
  display: none;
}
.sm-deliveredMobile {
  display: none;
}
.css-14sza3e-MuiStepLabel-root {
  padding: 0px !important;
}
.css-1hv8oq8-MuiStepLabel-label {
  line-height: 0.45 !important;
}
.flitericon {
  display: none;
}
.sm-myorderView {
  display: none;
}
.sm-block {
  display: none;
}
.sticky {
  position: sticky;
  top: 0px;
}
.offcanvas {
  width: 70% !important;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
  color: #0d354f !important;
}

.delete {
  float: right;
  padding-right: 0.6rem;
  margin-top: 0.1rem;
}
/* .css-15befwp-MuiRating-root {
  color: #0d354f !important;
} */
.milletHead {
  font-size: 2rem;
  font-weight: 700;
}
.viewHeart {
  border: 1px solid #d9d9d9;
  background-color: rgba(0, 0, 0, 0.5);
}
.viewItemHeart {
  color: #fff;
}
.amount-View {
  font-size: 16px;
  font-weight: 700;
  color: #000;
  margin-right: 1rem;
}
.amount-strike {
  font-size: 14px;
  font-weight: 500;
  color: #3e3f49;
  text-decoration: line-through;
  margin-right: 1rem;
}
.amount-discount {
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  background-color: #777474;
  padding: 5px 13px !important;
  border-radius: 20px;
}
.viewBuy-now {
  background-color: #86bc42 !important;
  padding: 5px 18px !important;
  border-radius: 20px !important;
  font-weight: 700 !important;
}
.viewadd-cart {
  background-color: #0d354f !important;
  color: #fff !important;
  border: 1px solid #000 !important;
  padding: 5px 15px !important;
  border-radius: 5px !important;
  font-weight: 700 !important;
  margin-left: 30px !important;
}
.increDecQ {
  overflow-wrap: break-word;
  white-space: normal;
  text-decoration: none;
  line-height: 1.2;
  letter-spacing: 0px;
  height: 22px;
  min-height: 22px;
  font-weight: normal;
  font-style: normal;
  font-size: 14px;
  color: rgb(0, 0, 0);
  background-color: #fff;
  border-width: 1px;
  border-color: #d11243;
  border-style: solid;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 18px;
  width: 140px;
  border-radius: 5px;
  justify-content: center;
}
.minusQ {
  padding: 0px 5px 0px 5px;
  cursor: pointer;
  opacity: 0.7;
  margin: 0px;
  font-weight: 700;
  font-size: 16px;
}
.plusQ {
  padding: 0px 5px 0px 5px;
  cursor: pointer;
  opacity: 0.7;
  margin: 0px;
  font-weight: 700;
  font-size: 16px;
}
.QinputQuantity {
  border-width: 0px;
  padding: 0px;
  width: 70px;
  text-align: center;
  outline: none;
  margin: 0px;
  font-weight: 600;
  font-style: normal;
  font-size: 16px;
  color: rgb(51, 51, 51);
  background-color: transparent;
}
/* .digital::after{
    content: "";
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    top: 6px;
    width: 100%;
    height: 3px;
    border-radius: 5px 5px 0px 0px;
    background: #000;
}
.customer::after{
    content: "";
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    top: 6px;
    width: 100%;
    height: 3px;
    border-radius: 5px 5px 0px 0px;
    background: #000;
}
.data::after{
    content: "";
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    top: 6px;
    width: 100%;
    height: 3px;
    border-radius: 5px 5px 0px 0px;
    background: #000;
} */
/* .buss-Nav::after{
    content: "";
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    top: 6px;
    width: 100%;
    height: 1px;
    border-radius: 5px 5px 0px 0px;
    background: #dedede;
} */
.D-row {
  display: flex;
  justify-content: space-between;
}
.D-row li {
  list-style-type: none;
  color: #000;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  cursor: pointer;
  background-color: transparent;
  padding: 5px 10px;
}
.avatar {
  width: 75px !important;
  height: 75px !important;
  border-radius: 0px !important;
}
.avatarOrder {
  width: 110px !important;
  height: 110px !important;
  border-radius: 0px !important;
}
.order-itemname {
  font-weight: 700;
  font-size: 18px;
}
.avatarlist-table {
  display: flex;
  justify-content: start;
  align-items: center;
}
.increDecshop {
  overflow-wrap: break-word;
  white-space: normal;
  text-decoration: none;
  line-height: 1.2;
  letter-spacing: 0px;
  height: 22px;
  min-height: 22px;
  font-weight: normal;
  font-style: normal;
  font-size: 14px;
  color: rgb(0, 0, 0);
  background-color: #fff;
  border-width: 1px;
  border-color: #86bc42;
  border-radius: 20px;
  border-style: solid;
  display: flex;
  align-items: center;
  padding: 20px 16px;
  width: fit-content;
  margin: auto;
}
.product-th {
  border-bottom: 2px solid #000;
}
.table-div {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px 20px 15px;
  width: 100% !important;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.item-avatar {
  display: flex;
  justify-content: start;
  align-items: center;
}
.item-avatar span {
  max-width: 130px; /* Adjust max-width as needed */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tax-box {
  width: 30%;
}
.tax-box p {
  font-size: 12px;
  font-size: 600;
}
.price-bar {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-left: 2rem;
}
.progress {
  --bs-progress-bar-bg: #86bc42;
  width: 354px !important;
  height: 10px !important;
}
.offercal {
  font-size: 14px;
  font-weight: 700;
  padding-top: 0.5rem;
}
.table-responsive {
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
}
.view-sm-img {
  height: 70px;
  width: 70px;
  border-radius: 10px;
  overflow: hidden;
}
.view-lg-img {
  height: 365px;
  width: 365px;
  border-radius: 10px;
  overflow: hidden;
}
.view-lg-img img {
  height: 370px;
  width: 365px;
}
.sm-img {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 365px;
}
.reviewTitle-box {
  font-size: 16px;
  font-weight: 700;
}
.description {
  font-weight: 700;
}
.t-h {
  color: #000 !important;
  background-color: #d9d9d9 !important;
}
.extraBox-Bill {
  font-size: 14px;
  font-weight: 700;
  color: #fff;
}
.checkoutbtn {
  background-color: #86bc42 !important;
  padding: 6px 26px !important;
  border-radius: 20px !important;
  font-weight: 700 !important;
}
:where(.css-dev-only-do-not-override-kghr11).ant-space-gap-col-small {
  font-size: 20px !important;
  font-weight: 700 !important;
  color: #454545 !important;
  cursor: pointer !important;
}
:where(.css-dev-only-do-not-override-kghr11).ant-dropdown
  .ant-dropdown-menu
  .ant-dropdown-menu-title-content {
  font-weight: 600 !important;
}
:where(.css-dev-only-do-not-override-kghr11).ant-dropdown
  .ant-dropdown-menu
  .ant-dropdown-menu-item:hover {
  border: 1px solid #86bc42 !important;
  background-color: #fff !important;
}
.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked {
  color: #86bc42 !important;
}
.Wm9vLv {
  position: relative;
  top: 10px;
  padding: 0 0px 0 40px;
  width: 90%;
  display: flex;
}
.YrA9Sn {
  color: #86bc42;
  transition: color 0.25s ease-in;
  /* transform: translateX(-45%) translateY(-10px) !important; */
  font-size: 12px;
  font-weight: 500;
}
.J1JsUj {
  display: inline-block;
}
.T7AO1y {
  background-color: #0d354f;
  transition: 0.25s ease-in;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  position: relative;
  cursor: pointer;
}
._9MhawT {
  width: calc(100% - 10px);
  margin-left: 11px;
  margin-top: -8px;
  height: 3px;
  background: #e0e0e0;
}

._9MhawTW {
  width: calc(100% - 10px);
  margin-left: 11px;
  margin-top: -8px;
  height: 3px;
  background: #0d354f;
}

.delivery {
  background-color: #0d354f;
  transition: 0.25s ease-in;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  position: relative;
  cursor: pointer;
}
._9MhawT-cancel {
  width: calc(100% - 10px);
  margin-left: 11px;
  margin-top: -8px;
  height: 3px;
  background: #e0e0e0;
}
._9XeNR9 {
  background: #0d354f;
  width: auto;
  height: 3px;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: center left;
  transform-origin: center left;
  transition: -webkit-transform 1s ease-in;
  transition: transform 1s ease-in;
  transition: transform 1s ease-in, -webkit-transform 1s ease-in;
  transition-delay: 0s;
  transform: scaleX(1);
}
.SfJXc8 {
  transform: translateX(-45%) translateY(10px);
  font-size: 12px;
  text-align: center;
  font-weight: 500;
  color: #000;
  width: 100px;
}
.SfJXc8-incomplete {
  transform: translateX(-45%) translateY(10px);
  font-size: 12px;
  text-align: center;
  font-weight: 500;
  color: #8c8c8c;
  width: 100px;
}
._9XeNR9-incomplete {
  background-color: #8c8c8c;
}
.SfJXc8O {
  transform: translateX(-45%) translateY(-8px);
  font-size: 12px;
  text-align: center;
  font-weight: 500;
  color: #0d354f;
  width: 100px;
}
.SfJXc8O-incomplete {
  transform: translateX(-45%) translateY(-8px);
  font-size: 12px;
  text-align: center;
  font-weight: 500;
  color: #0d354f;
  width: 100px;
}
.T7AO1y-incomplete {
  background-color: #d9d9d9;
  transition: 0.25s ease-in;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  position: relative;
  cursor: pointer;
}
.cancel-Order {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #474747;
  cursor: pointer;
}
.input-faq {
  width: 100%;
  border: 2px solid #86bc42;
  height: 40px;
  border-radius: 5px;
  box-shadow: 0px 9px 10px -6px rgba(0, 0, 0, 0.25);
}
.textarea-faq {
  width: 100%;
  border: 2px solid #86bc42;
  border-radius: 5px;
  box-shadow: 0px 9px 10px -6px rgba(0, 0, 0, 0.25);
}
.label-faq {
  font-weight: 700;
}
.faq-submitbtn {
  border-radius: 5px !important;
  font-weight: 700 !important;
  padding: 5px 40px !important;
}
.privacy p {
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 600;
}
.privacy h4 {
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: 700;
}
.privacy ul li {
  font-size: 14px;
  font-weight: 600;
}
.privacy a {
  color: #000;
}
.sm-cancel {
  padding: 3px 10px !important;
}
.head-Icon {
  font-size: 24px !important;
  color: #fff !important;
}
.head-Icon:hover {
  color: #001524 !important;
}
.css-104zre5-MuiInputBase-root .MuiInputBase-input {
  color: #858585 !important;
}
.sm-sidebar p {
  font-size: 1rem;
  font-weight: 700 !important;
}
.login-details label {
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 1rem;
}
.login-details input {
  width: 100%;
  border-radius: 10px;
  height: 40px;
  outline: none;
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
}
.login-details p {
  font-size: 16px;
  font-weight: 600;
}
.login-details span {
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
}
.position-loginlg {
  position: absolute;
  background-color: #001524;
  top: 83px;
  color: #fff;
  right: 10px;
  padding: 2rem;
  width: 500px;
  border-radius: 20px;
}
.signinBtn {
  width: 100%;
  height: 50px;
  background-color: #86bc42 !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  border-radius: 10px !important;
}
.new-address {
  height: 90vh;
  overflow-y: scroll;
}
.new-address h5 {
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  padding: 10px 0px;
}
.new-address label {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  padding: 10px 0px;
}
.new-address input {
  border: 1px solid #fff;
  border-radius: 10px;
  background-color: rgba(0, 21, 36, 1);
  width: 100%;
  height: 50px;
  padding-left: 10px;
  color: #fff;
}
.saveAddressAs {
  background-color: #fff !important;
  color: #000 !important;
  width: 100px;
  margin: 0px 4px !important;
}
.save-btn {
  background-color: #86bc42 !important;
  border-radius: 20px !important;
  margin-top: 1rem !important;
  width: 90% !important;
  font-weight: 600 !important;
  height: 40px !important;
}
.complete-line {
  background-color: #0d354f;
}
.complete-dot {
  background-color: #0d354f;
}
.complete-dates {
  color: #000;
}
.cancel-times p {
  color: #7a7a7a;
  font-size: 12px;
  font-weight: 600;
}
.order-cancel {
  background-color: red;
}
.css-4s9lit {
  border-radius: 10px !important;
}
.cancel-box h4 {
  font-size: 14px;
  font-weight: 600;
}
.cancel-box input {
  width: 100%;
}
.cancel-box textarea {
  width: 100%;
}
.confrim-btn button {
  padding: 5px 30px !important;
  background-color: #0d354f !important;
}
._9XeNR9C {
  position: absolute !important;
  width: 68% !important;
  background-color: #001524 !important;
  height: 3px !important;
  top: 23px;
  left: 50px;
}
.lg-cancel {
  display: block;
}
.sm-cancel {
  display: none;
}
@media (max-width: 768px) {
  .SfJXc8O-incomplete {
    font-size: 11px !important;
  }
  .css-4s9lit {
    width: 360px !important;
  }
  .sm-margin {
    margin-top: 20px;
  }
  ._9XeNR9C {
    position: absolute !important;
    width: 55% !important;
    background-color: #001524 !important;
    height: 3px !important;
    top: 23px;
    left: 50px;
  }
  .sm-align {
    margin: 25px 0px;
  }
  .lg-cancel {
    display: none;
  }
  .sm-cancel {
    display: block;
  }
}

/* Common styles */
.order-status {
  padding: 5px 10px;
  border-radius: 5px;
  color: white;
  display: inline-block;
  margin-bottom: 5px;
}

/* Status-specific styles */
.status-ordered,
.status-pending {
  background-color: #f39c12; /* Yellow */
}

.status-outfordelivery {
  background-color: #3498db; /* Blue */
}

.status-completed {
  background-color: #2ecc71; /* Green */
}

.status-cancelled {
  background-color: #e74c3c; /* Red */
}
